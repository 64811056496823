<template lang="pug">
  .subscription.view
    section.supercompact.deny-all(v-if="false")
      .section-content-wrapper
        UnsubscribeFromAll
    section.compact.subscription-edit
      .section-content-wrapper
        h2 Новая отписка
        h4 Укажите отправителя и отпишитесь:
        SubscriptionEditForm(@saved="$router.push({ name: 'subscriptions' })")
    HowItWorks
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {GET_SENDERS, SAVE_SUBSCRIBER_SUBSCRIPTION} from "@/store/types"

import UnsubscribeFromAll from "@/components/Subscription/UnsubscribeFromAll"
import SubscriptionEditForm from "@/components/Subscription/SubscriptionEditForm"
import HowItWorks from '@/components/HowItWorks'

const default_subscription_form = () => {
  return {
    subscriber_uid: undefined,
    channel_alias: 'viber',
    phone: '',
    sender_id: undefined,
    sender_name: '',
    confirmation_code: undefined,
  }
}

export default {
  metaInfo() {
    return {
      title: this.title
    }
  },
  components: {
    UnsubscribeFromAll,
    SubscriptionEditForm,
    HowItWorks,
  },
  data() {
    let checkSender = (rule, value, callback) => {
      if (!value && !this.subscription_form.sender_name) callback(new Error('Выберите отправителя'))

      callback();
    }

    return {
      remote_options: [],
      channels: [{
        alias: 'viber',
        name: 'Viber',
      }],
      senders: [],
      subscription_form: default_subscription_form(),
      subscription_form_rules: {
        channel_alias: [
          {required: true, message: 'Выберите канал', trigger: 'blur'},
        ],
        sender_id: [
          {validator: checkSender, trigger: 'blur'}
        ],
        phone: [
          {required: true, message: 'Укажите номер телефона', trigger: 'blur'},
        ],
      },
    }
  },
  computed: {
    ...mapGetters([
      'auth_user',
      'subscriber',
    ]),
    title() {
      return 'Новая отписка'
    },
  },
  created() {
    // this.GET_SENDERS().then((senders)=>{
    //     this.senders = senders
    // }).catch((error)=>{
    //     this.$root.pushAppMessages(error.response.data)
    // })
  },
  methods: {
    ...mapActions([
      GET_SENDERS,
      SAVE_SUBSCRIBER_SUBSCRIPTION,
    ]),
    remoteMethod(query) {
      //console.log(query)
    },
    saveSubscription() {
      this.$refs.subscription_form.validate((valid) => {
        if (valid) {
          return this.SAVE_SUBSCRIBER_SUBSCRIPTION(this.subscription_form).then(() => {
            //this.subscription_form = default_subscription_form()

            this.$router.push({name: 'subscriptions'})
          }).catch((error) => {
            this.$root.pushAppMessages(error.response.data)
          })
        } else return false
      })
    },
    confirmSubscription() {

    },
  },
}
</script>

<style lang="scss" scoped>
.subscription {
  .deny-all {
    background: $color--secondary;
    color: $color--black;
  }

  &-title {
    background: $color--background;
    color: $color--black;
  }

  &-edit {
    background: $color--background;
    color: $color--black;

    &-form {
      width: 50%;
    }

    @media (max-width: $max-width--tablets) {
      &-form {
        width: 100%;
      }
    }
  }
}
</style>
